import { DoubleSide, Vector3 } from 'three';
import { StyleChecker } from '../aurelia-components/styling/styling-manager';

export const collaborators: StyleChecker[] = [
  {
    conditionOperator: 'and',
    conditions: [
      {
        key: 'userData.properties.type',
        value: 'IFCBUILDINGELEMENTPROXY',
        operator: '='
      },
      {
        key: 'userData.properties.name',
        value: 'FM_COLLABORATEUR:Collaborateur:',
        operator: '*'
      },
    ],
    definitions: [
      {
        color: '#CA5A5A',
        side: DoubleSide,
        geometry: {
          replace: true,
          default: 'mate',
          scale: 1,
          position: new Vector3(0, 0.8, 0)
        }
      }
    ],
    applyToChildren: false
  }
];

export default collaborators;
