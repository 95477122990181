export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<g>
	<ellipse class="colorize-fill-with-fill" fill="#FFFFFF" cx="74.368" cy="74.833" rx="73.368" ry="73.831"/>
	<g>
		<defs>
			<path id="SVGID_1_" d="M149,75.168C149,115.944,116.151,149,75.632,149S2.264,115.944,2.264,75.168
				c0-40.776,32.848-57.415,73.368-57.415S149,34.392,149,75.168z"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  overflow="visible"/>
		</clipPath>
		<path class="colorize-fill-with-stroke" clip-path="url(#SVGID_2_)" fill="#5C9EA2" d="M88.333,96.082c7.773-5.252,12.966-14.757,12.966-25.61
			c0-16.54-12.057-28.638-26.931-28.638c-14.873,0-26.931,12.098-26.931,28.638c0,10.853,5.192,20.358,12.965,25.61
			c-19.408,7.799-32.894,30.98-32.894,52.918h93.719C121.228,127.062,107.742,103.881,88.333,96.082z"/>
	</g>
</g>
</svg>
`