export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
  <g>
    <circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
    <g>
      <path class="colorize-fill-with-stroke" fill="#D78484" d="M67.229,53.229v120h93v-120H67.229z M127.229,128.914v31.315h-11v-25.671c0,0.094-1.787,0.144-2.5,0.144
        c-0.715,0-1.5-0.05-2.5-0.144v25.671h-11v-32.075c-4-4.525-6.715-10.796-6.715-16.841c0-9.227,5.581-18.975,13.798-22.246
        c-3.291-2.203-5.562-6.191-5.562-10.757c0-6.95,5.065-12.035,11.354-12.035c6.291,0,11.373,5.085,11.373,12.035
        c0,4.565-2.214,8.554-5.507,10.757c8.217,3.271,14.674,13.02,14.674,22.246C133.646,117.705,130.229,124.348,127.229,128.914z"/>
      <path class="colorize-fill-with-stroke" fill="#D78484" d="M137.496,34.661l-21.288-21.168c-0.781-0.781-1.979-1.135-2.979-1.14
        c-1.023,0.005-2.047,0.424-2.828,1.205L89.188,34.65c-1.562,1.562-0.267,5.579,3.077,5.579c1.132,0,10.848,0,20.964,0
        c10,0,20.133,0,21.265,0C137.838,40.229,139.059,36.224,137.496,34.661z"/>
      <path class="colorize-fill-with-stroke" fill="#D78484" d="M137.496,192.056l-21.288,21.299c-0.781,0.781-1.979,1.198-2.979,1.203
        c-1.023-0.005-2.047-0.39-2.828-1.171l-21.213-21.336c-1.562-1.562-0.267-5.821,3.077-5.821c1.132,0,10.848,0,20.964,0
        c10,0,20.133,0,21.265,0C137.838,186.229,139.059,190.495,137.496,192.056z"/>
    </g>
  </g>
</svg>
`
