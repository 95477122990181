export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
	 width="227px" height="227px" viewBox="0 0 227 227" enable-background="new 0 0 227 227" xml:space="preserve">
<g>
	<circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
	<g>
		<path class="colorize-fill-with-stroke" fill="#85A4F3" d="M190.287,110.263c0-13.084-7.754-26.958-19.342-31.65c4.693-3.13,7.928-8.82,7.928-15.32
			c0-9.879-7.197-17.104-16.137-17.104s-16.162,7.225-16.162,17.104c0,6.476,3.365,12.147,8.029,15.287
			c-11.617,4.645-19.316,18.499-19.316,31.611c0,7.923,0,33.039,0,33.039h11v37h14v-37h5v37h15v-37h10
			C190.287,143.229,190.287,116.926,190.287,110.263z"/>
		<path class="colorize-fill-with-stroke" fill="#85A4F3" d="M93.703,110.19c0-13.102-8.191-26.945-19.858-31.602c4.675-3.137,7.753-8.814,7.753-15.297
			c0-9.879-7.268-17.104-16.207-17.104c-8.939,0-16.196,7.225-16.196,17.104c0,6.482,3.117,12.16,7.79,15.297
			C45.319,83.245,37.5,97.089,37.5,110.19c0,8.752,3.788,17.831,9.788,24.287v45.752h16v-36.971c1,0.109,1.628,0.172,2.542,0.172
			c0.885,0,1.458-0.061,2.458-0.162v36.961h16v-45.66C90.287,128.106,93.703,118.985,93.703,110.19z"/>
		<path class="colorize-fill-with-stroke" fill="#85A4F3" d="M113.287,46.335c-1.652,0-3,1.34-3,2.992v128.346c0,1.652,1.348,2.992,3,2.992s3-1.34,3-2.992V49.327
			C116.287,47.675,114.939,46.335,113.287,46.335z"/>
	</g>
</g>
</svg>
`