export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<g>
	<circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
	<g>
		<path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M121.625,116.668c7.501-3.719,12.729-12.112,12.729-21.784c0-13.139-9.639-22.786-21.529-22.786
			c-11.89,0-21.529,9.594-21.529,22.732c0,9.672,5.227,18.128,12.728,21.847c-15.257,4.953-25.911,22.553-27.049,38.553h71.7
			C147.537,139.229,136.883,121.621,121.625,116.668z"/>
		<path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M65.35,112.798c5.469-3.252,9.187-9.635,9.187-16.977c0-10.643-7.807-18.428-17.44-18.428
			c-9.63,0-17.438,7.785-17.438,18.428c0,7.344,3.719,13.87,9.188,17.122c-12.648,4.779-21.606,19.286-22.066,33.286h48.686
			c1.515-6,4.064-11.522,7.467-16.474C79.03,122.215,72.836,115.628,65.35,112.798z"/>
		<path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M177.188,112.945c5.469-3.254,9.188-9.708,9.188-17.052c0-10.643-7.809-18.464-17.439-18.464
			c-9.633,0-17.439,7.768-17.439,18.41c0,7.342,3.717,13.713,9.184,16.965c-7.592,2.873-13.853,9.604-17.744,17.286
			c3.291,4.871,5.766,10.139,7.247,16.139h49.069C198.794,132.229,189.837,117.723,177.188,112.945z"/>
	</g>
</g>
</svg>
`