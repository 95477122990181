export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<g>
	<circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
	<path class="colorize-fill-with-stroke" fill="#A5BDD1" d="M160.599,73.855l0.505-9.422c0-4.625-3.748-7.434-8.372-7.434H67.639c-4.624,0-8.37,2.809-8.37,7.434
		l5.58,99.831c0,4.623,3.747,8.735,8.368,8.735h73.936c4.623,0,8.371-4.58,8.371-9.203l1.867-23.802
		c20.812-0.168,35.881-14.218,35.881-33.19C193.271,88.82,179.732,75.34,160.599,73.855z M110.186,135.309
		c-14.482,0-26.226-11.744-26.226-26.229c0-14.482,11.743-26.225,26.226-26.225c14.483,0,26.227,11.742,26.227,26.225
		C136.412,123.564,124.669,135.309,110.186,135.309z M157.957,127.959l2.074-42.498c11.201,1.006,21.637,7.66,21.637,21.189
		C181.668,121.014,169.912,127.627,157.957,127.959z"/>
</g>
</svg>
`