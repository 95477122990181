export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<g>
	<circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
	<g>
		<path class="colorize-fill-with-stroke" fill="#A5BDD1" d="M97.5,43.558c-2.434,0-4.5,1.975-4.5,4.411V84H83V47.969c0-2.437-2.064-4.411-4.5-4.411
			S74,45.532,74,47.969V84h-9V47.969c0-2.437-2.064-4.411-4.5-4.411S56,45.532,56,47.969v36.018c0,5.515,6,10.566,14,12.636v76.304
			c0,4.684,4.277,8.478,8.961,8.478c4.682,0,8.717-3.794,8.717-8.478c0-0.288-0.147-67.811-0.152-76.304
			C96.061,94.552,102,89.501,102,83.986V47.969C102,45.532,99.936,43.558,97.5,43.558z"/>
		<path class="colorize-fill-with-stroke" fill="#A5BDD1" d="M170.32,58.539c0-8.339-6.76-15.152-15.101-15.152c-8.34,0-15.102,6.838-15.102,15.178
			c0,0.119,0.012,50.413,0.012,50.413c0.114,8.085,5.797,13.744,13.147,14.678c0,0-0.026,50.568-0.026,50.741
			c0,4.684,3.796,8.478,8.479,8.478s8.479-3.794,8.479-8.478C170.208,174.236,170.32,58.655,170.32,58.539z"/>
	</g>
</g>
</svg>
`