import { DoubleSide, Vector3 } from 'three';
import { StyleChecker } from '../aurelia-components/styling/styling-manager';

export const doubleSideWalls: StyleChecker[] = [
  {
    conditionOperator: 'or',
    conditions: [
      {
        key: 'userData.properties.type',
        value: 'IFCWALLSTANDARDCASE',
        operator: '='
      },
      {
        key: 'userData.properties.type',
        value: 'IFCDOOR',
        operator: '='
      },
      {
        key: 'userData.properties.type',
        value: 'IFCWALL',
        operator: '='
      },
      {
        key: 'userData.properties.type',
        value: 'IFCCURTAINWALL',
        operator: '='
      },
      {
        key: 'userData.properties.type',
        value: 'IFCWINDOW',
        operator: '='
      }
    ],
    definitions: [
      {
        side: DoubleSide
      }
    ],
    applyToChildren: false
  }
];

export default doubleSideWalls;
