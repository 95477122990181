export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<g><circle cx="114.457" cy="114.241" r="112.757" style="fill:#fff;"/>
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M99.022,70.833c-1.286,-0.01 -2.377,0.939 -2.544,2.213c-0.504,3.781 -2.405,7.236 -5.329,9.683c-2.924,2.449 -6.66,3.714 -10.47,3.546c-3.81,-0.169 -7.419,-1.758 -10.115,-4.455c-2.698,-2.697 -4.286,-6.306 -4.455,-10.116c-0.168,-3.81 1.096,-7.545 3.545,-10.47c2.448,-2.923 5.903,-4.825 9.683,-5.329c1.405,0 2.543,-1.138 2.543,-2.543c0,-1.404 -1.138,-2.543 -2.543,-2.543c-1.012,-0.123 -2.032,-0.174 -3.052,-0.152c-6.744,-0 -13.212,2.679 -17.982,7.449c-4.769,4.769 -7.449,11.237 -7.449,17.982c0,6.745 2.68,13.213 7.449,17.982c4.77,4.77 11.238,7.449 17.982,7.449c6.745,0 13.213,-2.679 17.983,-7.449c4.769,-4.769 7.449,-11.237 7.449,-17.982c0.003,-1.013 -0.065,-2.023 -0.204,-3.027c-0.153,-1.265 -1.218,-2.221 -2.492,-2.238l0.001,0Z" />
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M77.912,58.701c-0.815,0.572 -1.223,1.565 -1.043,2.544l0.687,4.247l-2.9,2.975c-0.656,0.67 -0.891,1.648 -0.61,2.543c0.305,0.905 1.09,1.564 2.035,1.705l3.942,0.61l1.78,3.79l-0,-0.001c0.413,0.893 1.304,1.468 2.289,1.475c1.011,0.022 1.94,-0.557 2.364,-1.475l1.781,-3.79l3.917,-0.611l-0,0.002c0.917,-0.166 1.671,-0.821 1.963,-1.708c0.291,-0.885 0.075,-1.861 -0.565,-2.54l-2.924,-2.975l0.687,-4.247l-0.001,-0c0.18,-0.979 -0.227,-1.972 -1.043,-2.544c-0.794,-0.563 -1.842,-0.622 -2.695,-0.152l-3.484,1.933l-3.484,-1.933c-0.854,-0.47 -1.901,-0.411 -2.695,0.152l-0.001,0Z" />
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M170.813,78.64l6.842,-11.419l-0.002,0c0.467,-0.786 0.475,-1.761 0.023,-2.556c-0.452,-0.793 -1.295,-1.284 -2.209,-1.284l-13.809,0l-6.893,-11.469c-0.457,-0.772 -1.288,-1.245 -2.186,-1.245c-0.898,-0 -1.73,0.473 -2.187,1.245l-6.893,11.469l-13.809,0c-0.914,0 -1.757,0.491 -2.209,1.284c-0.452,0.795 -0.444,1.77 0.023,2.556l6.84,11.419l-6.841,11.419l0.001,0c-0.467,0.786 -0.475,1.761 -0.023,2.556c0.452,0.793 1.295,1.284 2.209,1.284l13.809,-0l6.892,11.469l0.001,-0c0.457,0.772 1.288,1.245 2.187,1.245c0.898,0 1.729,-0.473 2.186,-1.245l6.893,-11.469l13.809,-0c0.914,-0 1.757,-0.491 2.209,-1.284c0.452,-0.795 0.444,-1.77 -0.023,-2.556l-6.84,-11.419Z" />
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M89,139.675l-10.172,-0l-0,-10.172c-0,-0.675 -0.268,-1.322 -0.745,-1.799c-0.477,-0.477 -1.124,-0.745 -1.798,-0.745l-10.173,0c-1.404,0 -2.543,1.139 -2.543,2.544l0,10.172l-10.172,-0c-1.405,-0 -2.544,1.139 -2.544,2.543l0,10.173c0,0.674 0.268,1.321 0.745,1.798c0.477,0.477 1.124,0.745 1.799,0.745l10.172,-0l0,20.345c0,0.674 0.268,1.321 0.745,1.798c0.477,0.477 1.124,0.745 1.798,0.745l10.173,-0c0.674,-0 1.321,-0.268 1.798,-0.745c0.477,-0.477 0.745,-1.124 0.745,-1.798l-0,-20.345l10.172,-0c0.675,-0 1.322,-0.268 1.799,-0.745c0.476,-0.477 0.744,-1.124 0.744,-1.798l0,-10.173c0,-0.674 -0.268,-1.321 -0.744,-1.798c-0.477,-0.477 -1.124,-0.745 -1.799,-0.745Z" />
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M178.009,151.501l0,-0.178c-0.05,-1.194 -0.186,-2.384 -0.406,-3.56c-1.465,-7.841 -6.522,-14.539 -13.663,-18.095c-7.14,-3.556 -15.533,-3.556 -22.673,-0c-7.14,3.556 -12.198,10.254 -13.663,18.095c-0.234,1.174 -0.37,2.364 -0.406,3.56l-0,1.068c-0,9.086 4.847,17.482 12.716,22.024c7.867,4.543 17.562,4.543 25.431,0c7.867,-4.542 12.715,-12.938 12.715,-22.024c-0.051,-0.305 -0.051,-0.61 -0.051,-0.89l0,0Zm-36.876,-4.196c1.543,-0 2.933,0.93 3.524,2.355c0.59,1.426 0.264,3.066 -0.827,4.157c-1.091,1.091 -2.731,1.417 -4.157,0.827c-1.425,-0.591 -2.355,-1.981 -2.355,-3.524c-0,-2.107 1.707,-3.815 3.815,-3.815l-0,-0Zm11.444,-15.259c4.753,-0.002 9.358,1.66 13.014,4.698c3.656,3.038 6.133,7.26 7,11.935c0.182,0.93 0.292,1.874 0.331,2.822c-0.069,3.18 -1.828,6.082 -4.615,7.613c-2.789,1.531 -6.182,1.458 -8.901,-0.19c-2.721,-1.65 -4.354,-4.625 -4.286,-7.805c-0.007,-3.708 -1.483,-7.261 -4.104,-9.883c-2.622,-2.621 -6.175,-4.097 -9.883,-4.104c-0.742,-0.006 -1.482,0.053 -2.213,0.178c3.742,-3.387 8.609,-5.264 13.657,-5.264l-0,0Z" />
    <path class="colorize-fill-with-stroke" fill="#DFBDBE" d="M167.837,151.12c-0,2.108 -1.708,3.815 -3.815,3.815c-2.107,0 -3.814,-1.707 -3.814,-3.815c-0,-2.107 1.707,-3.814 3.814,-3.814c2.107,-0 3.815,1.707 3.815,3.814" />
</g>
</svg>
`