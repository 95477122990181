export default `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 227">
<circle class="colorize-fill-with-fill" fill="#FFFFFF" cx="113.5" cy="113.499" r="112.757"/>
<g>
	<path class="colorize-fill-with-stroke" fill="#B2C1D1" d="M133.496,63.276h-8.115V21.738h-23.76v41.538h-8.115c-25.285,0-39.803,16.465-39.803,34.012h119.594
		C173.297,79.741,158.781,63.276,133.496,63.276z"/>
	<g>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="142.233" cy="134.186" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="123.079" cy="135.682" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="103.923" cy="135.682" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="84.767" cy="133.439" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="161.39" cy="131.195" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="65.611" cy="131.195" r="5.34"/>
	</g>
	<g>
		<path class="colorize-fill-with-stroke" fill="#B2C1D1" d="M159.271,111.17c0,2.947-2.391,5.338-5.34,5.338s-5.338-2.391-5.338-5.338c0-2.951,2.389-5.34,5.338-5.34
			S159.271,108.219,159.271,111.17z"/>
		<path class="colorize-fill-with-stroke" fill="#B2C1D1" d="M78.41,111.17c0,2.947-2.393,5.338-5.34,5.338c-2.949,0-5.34-2.391-5.34-5.338
			c0-2.951,2.391-5.34,5.34-5.34C76.018,105.83,78.41,108.219,78.41,111.17z"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="93.285" cy="112.664" r="5.34"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="113.501" cy="114.158" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="133.716" cy="112.664" r="5.339"/>
	</g>
	<g>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="151.812" cy="155.709" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="132.657" cy="157.205" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="113.501" cy="157.951" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="94.345" cy="157.205" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="75.189" cy="155.709" r="5.338"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="170.968" cy="152.717" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="56.033" cy="152.717" r="5.34"/>
	</g>
	<g>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="156.897" cy="180.184" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="135.2" cy="181.68" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="113.501" cy="183.924" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="91.803" cy="181.68" r="5.338"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="70.103" cy="177.941" r="5.339"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="178.598" cy="174.988" r="5.338"/>
		<circle class="colorize-fill-with-stroke" fill="#B2C1D1" cx="48.403" cy="174.988" r="5.339"/>
	</g>
</g>
</svg>
`